import styled from "styled-components"

export const MainWrapper = styled.div`
padding: 20px 0;
margin-bottom: 30px;
.call-action-wrapper{
    background-color: #f8fafc;
    border-radius: 6px;
    box-shadow: 0px 32px 162px 0px rgb(0 0 0 / 30%);
    padding: 70px 25px;
    text-align: center;

    h5{
        color: #222328;
    font-size: 34px;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 25px;
    }
    a{
        border: 1px solid #25c074;
    background: transparent;
    margin: 0 auto;
    color: #24b975 ;
    letter-spacing: 1px;
    border-radius: 25px;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 15px;
    padding: 8px 25px;
    transition: all 0.5s;
    :hover{
        color: #fff;
        background: #24b975;
    }
    }
}

`