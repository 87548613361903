import React, { useEffect } from "react"
import { StandardWrapper } from "./Pentest.styles"
import { Container, Col, Row } from "react-bootstrap"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import StdLogo1 from "../../images/owasp-logo.png"
import StdLogo2 from "../../images/osstmm-blue.png"
import StdLogo3 from "../../images/pci-logo.png"

function PentestStandards() {
    const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

    return (
        <StandardWrapper>
            <Container>
                <Row>
                    <Col>
                    <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, y: 0, transition: { duration: 1 } },
                hidden: { opacity: 0, y: 50 },
              }}
            >
                <h2>STANDARDS WE FOLLOW</h2>
                <div className="standards-img">
                <img src={StdLogo1}></img>
                <img src={StdLogo2}></img>
                <img src={StdLogo3}></img>
                </div>
            </motion.div>
                    </Col>
                </Row>
            </Container>
            
        </StandardWrapper>
    )
}

export default PentestStandards
