import React, { useEffect } from "react"
import { BenefitWrapper } from "./Pentest.styles"
import { Container, Col, Row } from "react-bootstrap"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import Cards from "../UI/Cards"

const container = {
    hidden: { opacity: 1, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.4
      }
    }
  };
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

export default function PentestBenefits() {
    const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <BenefitWrapper>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <motion.div>
              <motion.h2
              initial="{ y: 20, opacity: 0 }"
              animate="{
                y: 0,
                opacity: 1
              }">BENEFITS</motion.h2>
              
              <motion.ul class="stack-ul"
              ref={ref}
              animate={controls}
              variants={container}
              initial="hidden">
                <motion.li variants={item} ><p>Realtime testing progress</p></motion.li>
                <motion.li variants={item} ><p>Knowledge base</p></motion.li>
                <motion.li variants={item} > <p>24x7x365 testing</p> </motion.li>
                <motion.li variants={item} ><p>Flexible periodic testing </p></motion.li>
                <motion.li variants={item} ><p>Multiple project comparison  </p></motion.li>
                <motion.li variants={item} ><p>Customizable test plans</p></motion.li>
                <motion.li variants={item} ><p>Evidence based reports</p></motion.li>
                <motion.li variants={item} ><p>Full-time visibility with clients/ customers</p></motion.li>
              </motion.ul>
              
            </motion.div>
          </Col>
        </Row>
      </Container>
    </BenefitWrapper>
  )
}
