import React, { useEffect } from "react";
import { Link } from "gatsby"
import {Container, Col, Row} from "react-bootstrap"
import { MainWrapper } from "./CallToAction.styles"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"







const CallToAction = (props) => {
    
    
    const controls = useAnimation();
    const [ref, inView] = useInView();
  
    useEffect(() => {
      if (inView) {
        controls.start("visible");
      }
    }, [controls, inView]);

    return(
        <div>
        <MainWrapper>
           
                
            <Container>
                <Row>
                 

                    <Col lg={12} md={12} sm={12} xs={12}>
                    <motion.div className="call-action-wrapper" 
                    ref={ref}
                    animate={controls}
                    initial="hidden"
                    variants={{visible: { opacity: 1, y: 0, transition: { duration: 1 } },
                    hidden: { opacity: 0, y: 50 }}}>
                    
                       <h5>
                       {props.title}
                        </h5>
                        <a href={props.url}>{props.linktext}</a>
                    </motion.div>
                    </Col>
                </Row>
                
            </Container>
   
        </MainWrapper>

        </div>
    )
}
export default CallToAction;