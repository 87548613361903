import * as React from "react"
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "../components/Layout/Layout"
import Seo from "../components/seo";
import Pentest from "../components/Pentest";
import CallToAction from "../components/CallToAction";
import PentestService from "../components/Pentest/PentestService";
import PentestBenefits from "../components/Pentest/PentestBenefits";
import PentestStandards from "../components/Pentest/PentestStandards";



const IndexPage = () => (
  <Layout>
    <Seo title="PENTEST360" />
    <Pentest/>
    <PentestService/>
    <PentestBenefits/>
    <PentestStandards/>
    <CallToAction title="Want to learn more about penetration testing?" linktext="Contact Us" url="/contact"/>
  </Layout>
)

export default IndexPage
