import React, { useEffect }  from 'react'
import {MainWrapper} from './Pentest.styles'
import Banner from "../../images/Network-Penetration.png"
import {Container, Col, Row} from "react-bootstrap"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
function Pentest() {
    const controls = useAnimation();
    const [ref, inView] = useInView();
  
    useEffect(() => {
      if (inView) {
        controls.start("visible");
      }
    }, [controls, inView]);


    return (
        <MainWrapper>
            <Container>
                <Row>
                 

                    <Col lg={6} md={6} sm={12} xs={12}>
                    <motion.div class="vertical_center banner-text" 
                    ref={ref}
                    animate={controls}
                    initial="hidden"
                    variants={{visible: { opacity: 1, x: 0, transition: { duration: 1 } },
                    hidden: { opacity: 0, x: -50 }}}>
                   
                       <h1>
                       CONTINUOUS AUTOMATED TESTS COMPLIMENTED BY MANUAL TESTS
                        </h1>
                        <p>
                        <strong>PENTEST360</strong> was developed to deliver automated penetration testing complemented by manual tests, vulnerability management and enables end users to view progress in real time. Taking root from in-house R&D capabilities, our platform provides expanded features such as test plans, vulnerability database, etc.
                        </p>
                        <p>Additionally, a step-by-step process to recreate an identified vulnerability is also included to guide security practitioners through the testing process.</p>
                    </motion.div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                    <img src={Banner}/>
                    </Col>
                </Row>
                
            </Container>
        </MainWrapper>
    )
}

export default Pentest
