import React, { useEffect } from "react"
import { OfferWrapper } from "./Pentest.styles"
import { Container, Col, Row } from "react-bootstrap"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

import { FaLaptop } from "@react-icons/all-files/fa/FaLaptop"
import { FaNetworkWired } from "@react-icons/all-files/fa/FaNetworkWired"
import { FaClipboardList } from "@react-icons/all-files/fa/FaClipboardList"
import { FaAutoprefixer } from "@react-icons/all-files/fa/FaAutoprefixer"
import { FaSearch } from "@react-icons/all-files/fa/FaSearch"
import { FaSearchengin } from "@react-icons/all-files/fa/FaSearchengin"


function PentestService() {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <OfferWrapper>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, y: 0, transition: { duration: 1 } },
                hidden: { opacity: 0, y: 50 },
              }}
            >
              <h2>WHAT WE DO</h2>
              
            </motion.div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <FaLaptop/>
                  <h3>Web & Mobile Application Penetration Testing</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                  Find standard and business vulnerabilities within applications. 
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <FaNetworkWired/>
                  <h3>Network Penetration Testing</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                  Pre-authorize  our team to break into your network ethically,  with limited knowledge about your organization.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <FaClipboardList/>
                  <h3>Vulnerability Assessment</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                  Get a detailed understanding of your weaknesses, gaps, vulnerability points to prioritise mitigation actions and reduce overall risks. 
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <FaAutoprefixer/>
                  <h3>Automan Testing</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                  Enabling security teams to select specific vulnerabilities from a set of testing packages thereby reducing cost and maximising efficiency. 
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <FaSearch/>
                  <h3>Rogue Port Scanning</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                  Determine the rogue/unknown ports which are accessible over the internet.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <FaSearchengin/>
                  <h3>COPS Scanning</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                  Identify weak/affected open source and CMS components being used within your applications.
                  </p>
                </div>
              </div>
            </div>
          </Col>
         
        </Row>
      </Container>
    </OfferWrapper>
  )
}

export default PentestService
